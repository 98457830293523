// @flow
import type {HttpService} from 'modules/common/services/http';

/**
 * Сервис для моковых данных
 */
export class ExampleApi {
    /**
     * Сервис для отправки HTTP запросов
     */
    _http: HttpService;

    /**
     * @param http Сервис для отправки HTTP запросов
     */
    constructor(http: HttpService) {
        this._http = http;
    }

    /**
     * Генерирует моковые данные
     * @returns данные
     */
    async getExample(): any {
        return {
            data: {
                id1: {
                    id: 'id1',
                    name: 'Hello world',
                },
                id2: {
                    id: 'id2',
                    name: 'Hello world',
                },
            },
            success: true,
        };
    }
}
