// @flow
import type {HttpService} from 'modules/common/services/http';
import type {TOrderReceivingListQuery, TOrderReceivingListResponse} from 'modules/order/types/order-receiving';

/**
 * Сервис для данных вариантов доставки и самовывоза
 */
export class OrderReceivingApi {
    /**
     * Сервис для отправки HTTP запросов
     */
    _http: HttpService;

    /**
     * @param http Сервис для отправки HTTP запросов
     */
    constructor(http: HttpService) {
        this._http = http;
    }

    /**
     * Загружает список данных вариантов доставки и самовывоза
     * @param query параметры запроса
     * @returns данные
     */
    getList(query: TOrderReceivingListQuery) {
        return this._http.get<TOrderReceivingListResponse>('/api/v1/delivery/variants', {query});
    }
}
