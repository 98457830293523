// @flow
import {rootReducer} from 'app/reducers';
import {DadataService} from 'modules/common/services/dadata';
import {HttpService} from 'modules/common/services/http';
import {ExampleApi} from 'modules/example/services/example-api';
import {AddressApi} from 'modules/order/services/address-api';
import {OrderReceivingApi} from 'modules/order/services/order-receiving-api';
import {PickupPointApi} from 'modules/order/services/pickup-point-api';
import thunk from 'redux-thunk';
import type {TAppConfig} from 'types';
import {makeStore} from './make-store';

/**
 * Фабрика для создания сервисов
 * @param appConfig Конфиг приложения
 * @returns Сервисы
 */
function createServices(appConfig: TAppConfig) {
    const http = new HttpService(appConfig.apiUrl, appConfig.clientKey);
    const dadata = new DadataService(appConfig.dadataUrl, appConfig.dadataPath, appConfig.dadataKey);

    return {
        addressApi: new AddressApi(dadata),
        exampleApi: new ExampleApi(http),
        orderReceivingApi: new OrderReceivingApi(http),
        pickupPointApi: new PickupPointApi(http),
    };
}

export type TThunkExtraArgs = $Call<typeof createServices, TAppConfig>;

/**
 * Создает redux-store для приложения
 * @param appConfig Конфиг приложения
 * @returns redux-store
 */
export function createAppStore(appConfig: TAppConfig) {
    const extraArgs = createServices(appConfig);
    const store = makeStore({
        debug: 'production' !== process.env.NODE_ENV,
        middlewares: [thunk.withExtraArgument(extraArgs)],
        reducer: rootReducer,
    });

    if (module.hot) {
        module.hot.accept('app/reducers', () => store.replaceReducer(rootReducer));
    }

    return store;
}
