// @flow
import type {TConfigState} from 'modules/common/reducers/config';
import {configReducer} from 'modules/common/reducers/config';
import {combineReducers} from 'redux';

export type TCommonModuleState = {
    config: TConfigState,
};

export const commonReducers = combineReducers({
    config: configReducer,
});
