// @flow
import {ORDER_RECEIVING_ACTION_TYPE} from 'modules/order/constants';
import {transformOrderReceivingList} from 'modules/order/helpers/transform';
import type {
    TActionGetOrdRecClear,
    TActionGetOrdRecListFail,
    TActionGetOrdRecListStart,
    TActionGetOrdRecListSuccess,
} from 'modules/order/reducers/order-receiving';
import type {TOrderReceivingListQuery} from 'modules/order/types/order-receiving';
import type {TThunkAction} from 'types';

/**
 * @returns экшен
 */
function getOrderReceivingListFail(): TActionGetOrdRecListFail {
    return {
        type: ORDER_RECEIVING_ACTION_TYPE.GET_LIST_FAIL,
    };
}

/**
 * @returns экшен
 */
function getOrderReceivingListStart(): TActionGetOrdRecListStart {
    return {
        type: ORDER_RECEIVING_ACTION_TYPE.GET_LIST_START,
    };
}

/**
 * @param payload данные
 * @returns экшен
 */
function getOrderReceivingListSuccess(payload): TActionGetOrdRecListSuccess {
    return {
        payload,
        type: ORDER_RECEIVING_ACTION_TYPE.GET_LIST_SUCCESS,
    };
}

/**
 * @returns экшен
 */
export function clearOrderReceiving(): TActionGetOrdRecClear {
    return {
        type: ORDER_RECEIVING_ACTION_TYPE.CLEAR,
    };
}

/**
 * @param query параметры запроса
 * @returns thunk-экшен
 */
export function getOrderReceivingList(query: TOrderReceivingListQuery): TThunkAction<Promise<void>> {
    return async (dispatch, getState, {orderReceivingApi}) => {
        dispatch(getOrderReceivingListStart());
        try {
            const orderReceivingResponse = await orderReceivingApi.getList(query);
            if (orderReceivingResponse.errors && !orderReceivingResponse.errors.length) {
                dispatch(getOrderReceivingListSuccess(transformOrderReceivingList(orderReceivingResponse.data)));
            } else {
                dispatch(getOrderReceivingListFail());
            }
        } catch (e) {
            dispatch(getOrderReceivingListFail());
        }
    };
}
