// @flow
import cn from 'classnames';
import {ReactComponent as DownArrowIcon} from 'modules/common/icons/down-arrow.svg';
import type {TOption} from 'modules/common/types';
import Dropdown, {Option} from 'rc-select';
import React, {useCallback, useMemo} from 'react';
import './styles.less';

type TDropdownProps = {
    disabled?: boolean,
    listHeight?: number,
    name?: string,
    notFoundContent?: string,
    onBlur?: (?string) => void,
    onChange: (value: string, name?: string) => void,
    onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
    options: TOption[],
    placeholder?: string,
    value: ?string,
};

/**
 * Компонент селекта
 * @param {*} props Свойства компонента.
 * @return {undefined}
 */
export const Select = ({
    disabled,
    onBlur,
    onFocus,
    options,
    value,
    placeholder,
    name,
    onChange,
    notFoundContent = 'Нет вариантов',
    listHeight = 200,
}: TDropdownProps) => {
    /** Позволяет получать имя поля помимо значения селекта, чтобы не писать для каждого селекта в форме свой onChange*/
    const handleChange = useCallback(
        (newValue) => {
            onChange(newValue, name);
        },
        [onChange, name]
    );

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(name);
        }
    }, [onBlur, name]);

    const optionsList = useMemo(
        () =>
            options
                ? options.map((option, i) => (
                      <Option key={i} value={option.value}>
                          {option.label}
                      </Option>
                  ))
                : null,
        [options]
    );

    return (
        <Dropdown
            disabled={disabled}
            inputIcon={<DownArrowIcon className={cn('icon-default')} />}
            listHeight={listHeight}
            notFoundContent={notFoundContent}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={onFocus}
            optionLabelProp="children"
            placeholder={placeholder}
            value={value}
        >
            {optionsList}
        </Dropdown>
    );
};
