// @flow
const monthNames = {ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']};
const weekdaysLongNames = {ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота']};
const weekdaysShortNames = {ru: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']};
const firstDay = {
    ru: 1,
};

/**
 * Вывод дня недели, числа месяца, названия месяца и года в формате "Понедельник, 20 Апрель 2020"
 * @param {Date} day - дата
 * @param {string} locale - локаль
 * @returns string
 */
export const formatDay = (day: Date, locale: string = 'ru'): string =>
    `${weekdaysLongNames[locale][day.getDay()]}, ${day.getDate()} ${monthNames[locale][day.getMonth()]} ${day.getFullYear()}`;

/**
 * Вывод названия месяца и года в формате "Апрель 2020"
 * @param {Date} day - дата
 * @param {string} locale - локаль
 * @returns string
 */
export const formatMonthTitle = (day: Date, locale: string = 'ru') => `${monthNames[locale][day.getMonth()]} ${day.getFullYear()}`;

/**
 * Вывод сокращенного названия дня недели
 * @param {number} index - индекс дня недели
 * @param {string} locale - локаль
 * @returns string
 */
export const formatWeekdayShort = (index: number, locale: string = 'ru') => weekdaysShortNames[locale][index];

/**
 * Вывод длинного названия дня недели
 * @param {number} index - индекс дня недели
 * @param {string} locale - локаль
 * @returns string
 */
export const formatWeekdayLong = (index: number, locale: string = 'ru') => weekdaysLongNames[locale][index];

/**
 * Вывод первого дня недели, где 0 - ВС, 1 - ПН
 * @param {string} locale - локаль
 * @returns number
 */
export const getFirstDayOfWeek = (locale: string = 'ru'): number => firstDay[locale];

/**
 * Вывод массива с названиями месяцев
 * @param {string} locale - локаль
 * @returns Array<string>
 */
export const getMonths = (locale: string = 'ru'): Array<string> => monthNames[locale];
