// @flow
import {Select} from 'modules/common/components/Select';
import type {TOption} from 'modules/common/types';
import React, {useCallback, useEffect, useMemo} from 'react';

type TProps = {
    deliverySelectItems: TOption[],
    deliveryVariantId: ?string,
    handleDeliveryVariantChange: (?string) => void,
};

/**
 * Компонент выбора типа доставки
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderDeliveryTypeControl = ({handleDeliveryVariantChange, deliverySelectItems, deliveryVariantId}: TProps) => {
    const length = useMemo(() => deliverySelectItems.length, [deliverySelectItems]);

    useEffect(() => {
        if (1 === length) {
            handleDeliveryVariantChange(deliverySelectItems[0].value);
        }
    }, [handleDeliveryVariantChange, deliverySelectItems, length]);

    const renderContent = useCallback(() => {
        if (0 === length) {
            return <p className="optima__empty-delivery-types">Отсутствуют доступные варианты доставки</p>;
        }

        if (1 < length) {
            return (
                <div className="optima__form-item">
                    <Select
                        onChange={handleDeliveryVariantChange}
                        options={deliverySelectItems}
                        placeholder="Выберите тип доставки"
                        value={deliveryVariantId}
                    />
                </div>
            );
        }

        return null;
    }, [handleDeliveryVariantChange, deliverySelectItems, deliveryVariantId, length]);

    return (
        <>
            {renderContent()}
            <input name="optima-deliveryTypeId" type="hidden" value={deliveryVariantId || ''} />
        </>
    );
};
