// @flow
import {RenderPickupPointContext} from 'app/context';
import type {TPickupPoint} from 'modules/order/types/pickup-point';
import React, {useContext, useEffect, useRef} from 'react';

type TProps = {
    id: string,
    item: TPickupPoint,
    name: string,
    value: string,
};

/**
 * Компонент итема самовывоза
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderPickupItemHtml = ({item, id, value, name}: TProps) => {
    const renderItem = useContext(RenderPickupPointContext);
    const ref = useRef();

    useEffect(() => {
        const currentRef = ref.current;

        if (currentRef && renderItem) {
            const element = renderItem({id, item, name, value});
            currentRef.innerHTML = '';
            currentRef.appendChild(element);
        }
    }, [name, id, item, value, renderItem]);

    return <div className="optima__pickup-item-html" ref={ref} />;
};
