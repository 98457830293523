// @flow
import {format, parse} from 'date-fns';
import locale from 'date-fns/locale/ru';

/**
 * Форматирует дату в нужный для отображения формат
 * @param date дата
 * @param dateFormat формат в которой нужно отформатировать
 * @returns {string|null} результат форматирования
 */
export function formatDate(date: ?Date, dateFormat: string) {
    if (!date) {
        return null;
    }

    return format(date, dateFormat, {locale});
}

/**
 * Парсит строку с датой в дату
 * @param dateString строка с датой которую нужно спарсить
 * @param dateFormat формат в котором эта строка представленна
 * @returns {null|Date} результат парсинга
 */
export function parseDate(dateString: ?string, dateFormat: string) {
    if (!dateString) {
        return null;
    }

    return parse(dateString, dateFormat, new Date());
}
