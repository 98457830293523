// @flow
import type {TOrderReceivingListPayload, TOrderReceivingListResponse} from 'modules/order/types/order-receiving';
import type {TPickupPointListPayload, TPickupPointResponse} from 'modules/order/types/pickup-point';
import type {TEmptyObject} from 'types';

/**
 * Достает из итема id
 * @param item итем сущности
 * @returns {number} id сущности
 */
function getId(item: {id: number, [key: string]: any}) {
    return String(item.id);
}

type TItemWithId<T> = {...T, id: number};

/**
 * Кладет итем в дату.
 * @param acc дата
 * @param item вариант доставки
 * @returns дата
 */
function setItemInData<T>(acc: {[key: string]: TItemWithId<T>}, item: TItemWithId<T>): {[key: string]: TItemWithId<T>} {
    acc[String(item.id)] = item;
    return acc;
}

/**
 * Трансформирует ответ сервера в необходимый для редьюсера формат
 * @param response дата ответа сервера
 * @returns {TOrderReceivingListPayload} данные для редьюсера
 */
export function transformOrderReceivingList(response: TOrderReceivingListResponse | TEmptyObject): TOrderReceivingListPayload {
    const list = response.deliveryVariantList || [];
    const idList = list.map(getId);
    const data = list.reduce(setItemInData, {});

    return {
        data,
        list: idList,
    };
}

/**
 * Трансформирует ответ сервера в необходимый для редьюсера формат
 * @param response дата ответа сервера
 * @returns {TPickupPointListPayload} данные для редьюсера
 */
export function transformPickupPointList(response: TPickupPointResponse | TEmptyObject): TPickupPointListPayload {
    const pickupPointList = response.list || [];
    const idList = pickupPointList.map(getId);
    const data = pickupPointList.reduce(setItemInData, {});

    return {
        data,
        list: idList,
    };
}
