// @flow
export const ORDER_MODULE_NAME: 'order' = 'order';

export const ORDER_RECEIVING_ACTION_TYPE: {
    CLEAR: 'ORDER__CLEAR_ORDER_RECEIVING',
    GET_LIST_FAIL: 'ORDER__GET_ORDER_RECEIVING_LIST_FAIL',
    GET_LIST_START: 'ORDER__GET_ORDER_RECEIVING_LIST_START',
    GET_LIST_SUCCESS: 'ORDER__GET_ORDER_RECEIVING_LIST_SUCCESS',
} = {
    CLEAR: 'ORDER__CLEAR_ORDER_RECEIVING',
    GET_LIST_FAIL: 'ORDER__GET_ORDER_RECEIVING_LIST_FAIL',
    GET_LIST_START: 'ORDER__GET_ORDER_RECEIVING_LIST_START',
    GET_LIST_SUCCESS: 'ORDER__GET_ORDER_RECEIVING_LIST_SUCCESS',
};

export const PICKUP_POINT_ACTION_TYPE: {
    CLEAR: 'ORDER__CLEAR_PICKUP_POINT_LIST',
    GET_LIST_FAIL: 'ORDER__GET_PICKUP_POINT_LIST_FAIL',
    GET_LIST_START: 'ORDER__GET_PICKUP_POINT_LIST_START',
    GET_LIST_SUCCESS: 'ORDER__GET_PICKUP_POINT_LIST_SUCCESS',
} = {
    CLEAR: 'ORDER__CLEAR_PICKUP_POINT_LIST',
    GET_LIST_FAIL: 'ORDER__GET_PICKUP_POINT_LIST_FAIL',
    GET_LIST_START: 'ORDER__GET_PICKUP_POINT_LIST_START',
    GET_LIST_SUCCESS: 'ORDER__GET_PICKUP_POINT_LIST_SUCCESS',
};

export const ADDRESS_ACTION_TYPE: {
    CLEAR_GEOLOCATION_LIST: 'ORDER__CLEAR_GEOLOCATION_LIST_ADDRESS',
    CLEAR_SUGGESTION_LIST: 'ORDER__CLEAR_SUGGESTION_LIST_ADDRESS',
    GET_GEOLOCATION_LIST_FAIL: 'ORDER__GET_GEOLOCATION_ADDRESS_FAIL',
    GET_GEOLOCATION_LIST_START: 'ORDER__GET_GEOLOCATION_ADDRESS_START',
    GET_GEOLOCATION_LIST_SUCCESS: 'ORDER__GET_GEOLOCATION_ADDRESS_SUCCESS',
    GET_SUGGESTION_LIST_FAIL: 'ORDER__GET_SUGGESTION_ADDRESS_FAIL',
    GET_SUGGESTION_LIST_START: 'ORDER__GET_SUGGESTION_ADDRESS_START',
    GET_SUGGESTION_LIST_SUCCESS: 'ORDER__GET_SUGGESTION_ADDRESS_SUCCESS',
} = {
    CLEAR_GEOLOCATION_LIST: 'ORDER__CLEAR_GEOLOCATION_LIST_ADDRESS',
    CLEAR_SUGGESTION_LIST: 'ORDER__CLEAR_SUGGESTION_LIST_ADDRESS',
    GET_GEOLOCATION_LIST_FAIL: 'ORDER__GET_GEOLOCATION_ADDRESS_FAIL',
    GET_GEOLOCATION_LIST_START: 'ORDER__GET_GEOLOCATION_ADDRESS_START',
    GET_GEOLOCATION_LIST_SUCCESS: 'ORDER__GET_GEOLOCATION_ADDRESS_SUCCESS',
    GET_SUGGESTION_LIST_FAIL: 'ORDER__GET_SUGGESTION_ADDRESS_FAIL',
    GET_SUGGESTION_LIST_START: 'ORDER__GET_SUGGESTION_ADDRESS_START',
    GET_SUGGESTION_LIST_SUCCESS: 'ORDER__GET_SUGGESTION_ADDRESS_SUCCESS',
};

export const DELIVERY_TYPE = '1';
export const PICKUP_TYPE = '2';

export const ORDER_TYPE_LIST = [
    {id: 'delivery_radio', label: 'Доставка', value: DELIVERY_TYPE},
    {id: 'pickup_radio', label: 'Самовывоз', value: PICKUP_TYPE},
];

export const TYPES_COUNT = ORDER_TYPE_LIST.length;
