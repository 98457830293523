// @flow
import {COMMON_MODULE_NAME} from 'modules/common/constants';
import type {TRootState} from 'types';

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectModule(state: TRootState) {
    return state[COMMON_MODULE_NAME];
}

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectConfigState(state: TRootState) {
    return selectModule(state).config;
}

/**
 * Селектор конфига
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectConfigData(state: TRootState) {
    return selectConfigState(state).config;
}
