// @flow
import {useOrderReceivingClear} from 'modules/order/hooks/use-order-receiving';
import {usePickupPointClear} from 'modules/order/hooks/use-pickup-point';
import {selectOrderReceivingItem} from 'modules/order/selectors/order-receiving';
import type {TAddress} from 'modules/order/types/address';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

export type TCoordinates = {
    lat: ?string,
    lon: ?string,
};
export type TMapCoordinates = [number, number];

/**
 * Хранит и управляет изменением состояния формы заказа
 * @return значения полей и функции изменения
 */
export function useOrderFormState() {
    const [type, setType] = useState();

    const [address, setAddress] = useState<?TAddress>();
    const [addressValue, setAddressValue] = useState<string>('');
    const [mapCoordinates, setMapCoordinates] = useState<?TMapCoordinates>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [deliveryVariantId, setDeliveryVariantId] = useState();
    const [date, setDate] = useState<?Date>();
    const [timeItemIndex, setTimeItemIndex] = useState();

    const deliveryVariantItem = useSelector((state) => selectOrderReceivingItem(state, deliveryVariantId));
    const clearOrderReceiving = useOrderReceivingClear();
    const clearPickupPoint = usePickupPointClear();
    const coordinates = useCoordinates(mapCoordinates, address);

    const handleTypeChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            clearPickupPoint();
            clearOrderReceiving();
            setType(e.currentTarget.value);
            setAddress(null);
            setAddressValue('');
            setDeliveryVariantId(null);
            setTimeItemIndex(null);
            setDate(null);
        },
        [clearOrderReceiving, clearPickupPoint, setType, setDeliveryVariantId]
    );

    const handleAddressChange = useCallback(
        (newAddress: ?TAddress) => {
            setAddress(newAddress);
            clearOrderReceiving();
            setMapCoordinates(null);
            setDate(null);
            setDeliveryVariantId(null);
            setTimeItemIndex(null);
        },
        [setAddress, setDeliveryVariantId, clearOrderReceiving, setMapCoordinates]
    );

    const closeModalChosen = useCallback(
        (newCoordinates: ?TMapCoordinates, newAddress: ?TAddress) => {
            setIsModalVisible(false);
            setMapCoordinates(newCoordinates);
            clearOrderReceiving();
            setDeliveryVariantId(null);
            setTimeItemIndex(null);
            if (newAddress) {
                setAddressValue(newAddress.value);
                setAddress(newAddress);
            } else {
                setAddressValue('');
                setAddress(null);
            }
        },
        [setAddress, setDeliveryVariantId, clearOrderReceiving, setMapCoordinates]
    );

    const closeModalCancel = useCallback(() => {
        setIsModalVisible(false);
    }, [setIsModalVisible]);

    const openModal = useCallback(() => {
        if (mapCoordinates) {
            setMapCoordinates([...mapCoordinates]);
        }
        setIsModalVisible(true);
    }, [setMapCoordinates, mapCoordinates, setIsModalVisible]);

    const handleDateChange = useCallback(
        (newDate: ?Date) => {
            setDate(newDate);
            setTimeItemIndex(null);
        },
        [setDate]
    );

    const handleDeliveryVariantChange = useCallback(
        (newDeliveryVariantId: ?string) => {
            setDeliveryVariantId(newDeliveryVariantId);
            setDate(null);
            setTimeItemIndex(null);
        },
        [setDate, setDeliveryVariantId]
    );

    const handleTimeItemChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            const newValue = e.currentTarget.value;
            setTimeItemIndex(newValue);
        },
        [setTimeItemIndex]
    );

    return {
        address,
        addressValue,
        closeModalCancel,
        closeModalChosen,
        coordinates,
        date,
        deliveryVariantId,
        deliveryVariantItem,
        handleAddressChange,
        handleDateChange,
        handleDeliveryVariantChange,
        handleTimeItemChange,
        handleTypeChange,
        isModalVisible,
        mapCoordinates,
        openModal,
        setAddressValue,
        timeItemIndex,
        type,
    };
}

/**
 * Получает координаты из объека адреса или точки на карте
 * @param mapCoordinates координаты точки на карте
 * @param address адрес доставки
 * @returns {TCoordinates} координаты адреса
 */
export function useCoordinates(mapCoordinates: ?TMapCoordinates, address: ?TAddress) {
    return useMemo(() => {
        if (mapCoordinates && mapCoordinates.length) {
            return {lat: String(mapCoordinates[0]), lon: String(mapCoordinates[1])};
        }

        if (address && address.data && address.data.city && address.data.street && address.data.house) {
            return {lat: address.data.geo_lat, lon: address.data.geo_lon};
        }

        return {
            lat: null,
            lon: null,
        };
    }, [address, mapCoordinates]);
}
