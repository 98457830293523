// @flow
export const COMMON_MODULE_NAME: 'common' = 'common';

export const CONFIG_ACTION_TYPE: {
    SET_CONFIG: 'COMMON__SET_CONFIG',
} = {
    SET_CONFIG: 'COMMON__SET_CONFIG',
};

export const DEFAULT_DAY_LIMIT = 7;
export const MAX_DAY_LIMIT = 20;
