// @flow
import {CONFIG_ACTION_TYPE, DEFAULT_DAY_LIMIT, MAX_DAY_LIMIT} from 'modules/common/constants';
import {isNumber} from 'modules/common/helpers/type-check';
import {createReducer} from 'modules/redux/reducer';
import type {TAppConfig} from 'types';

export type TActionSetConfig = {payload: TAppConfig, type: typeof CONFIG_ACTION_TYPE.SET_CONFIG};

export type TConfigState = {|
    config: TAppConfig,
|};

export const initialState: TConfigState = {
    config: {
        apiUrl: '',
        cityId: '',
        clientKey: '',
        dadataKey: '',
        dadataPath: '',
        dadataUrl: '',
        dateLimit: DEFAULT_DAY_LIMIT,
        yandexMapKey: '',
    },
};

const reducer = {
    [CONFIG_ACTION_TYPE.SET_CONFIG]: (state, payload: TAppConfig) => ({
        ...state,
        config: {...state.config, ...payload, dateLimit: getCorrectDateLimit(payload.dateLimit)},
    }),
};

/**
 * Возвращает корректный лимит дат, обрабатывает случаи слишком большого количества дней и некорректного значения
 * @param dateLimit полученный лимит дат
 * @returns {number} корректный лимит дат
 */
function getCorrectDateLimit(dateLimit: ?number): number {
    if (!isNumber(dateLimit)) {
        return DEFAULT_DAY_LIMIT;
    }

    if (dateLimit > MAX_DAY_LIMIT) {
        return MAX_DAY_LIMIT;
    }

    return dateLimit;
}

export const configReducer = createReducer(initialState, reducer);
