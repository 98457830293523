// @flow
import {getListFromData} from 'modules/common/helpers/selectors';
import {ORDER_MODULE_NAME} from 'modules/order/constants';
import type {TPickupPointList} from 'modules/order/types/pickup-point';
import {createSelector} from 'reselect';
import type {TRootState} from 'types';

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectModule(state: TRootState) {
    return state[ORDER_MODULE_NAME];
}

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectPickupPointState(state: TRootState) {
    return selectModule(state).pickupPoint;
}

/**
 * Селектор сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectPickupPointData(state: TRootState) {
    return selectPickupPointState(state).data;
}

/**
 * Селектор списка id
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectPickupPointIdList(state: TRootState) {
    return selectPickupPointState(state).list;
}

/**
 * Селектор флага загрузки
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectPickupPointStatus(state: TRootState) {
    return selectPickupPointState(state).status;
}

/**
 * Селектор списка сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export const selectPickupPointList: (state: TRootState) => TPickupPointList = createSelector(
    [selectPickupPointData, selectPickupPointIdList],
    getListFromData
);
