// @flow
import {isObject} from 'modules/common/helpers/type-check';

/**
 * Преобразует переданный объект в FormData
 * @param element поля формы в виде обекта
 * @param form частично собранная форма
 * @param namespace пространство родителя
 * @returns {FormData} поля формы в виде FormData
 */
export function toFormData(element: any, form?: FormData, namespace?: string) {
    let fd = form || new FormData();
    let formKey;

    for (let key in element) {
        if (Object.prototype.hasOwnProperty.call(element, key)) {
            const value = element[key];

            if (namespace) {
                formKey = `${namespace}[${key}]`;
            } else {
                formKey = key;
            }

            if (isObject(value) && !(value instanceof File)) {
                toFormData(value, fd, formKey);
            } else {
                fd.append(formKey, value);
            }
        }
    }
    return fd;
}
