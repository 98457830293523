// @flow
import {addressReducer} from 'modules/order/reducers/address';
import type {TAddressState} from 'modules/order/reducers/address';
import type {TOrderReceivingState} from 'modules/order/reducers/order-receiving';
import {orderReceivingReducer} from 'modules/order/reducers/order-receiving';
import type {TPickupPointState} from 'modules/order/reducers/pickup-point';
import {pickupPointReducer} from 'modules/order/reducers/pickup-point';
import {combineReducers} from 'redux';

export type TOrderModuleState = {
    address: TAddressState,
    orderReceiving: TOrderReceivingState,
    pickupPoint: TPickupPointState,
};

export const orderReducers = combineReducers({
    address: addressReducer,
    orderReceiving: orderReceivingReducer,
    pickupPoint: pickupPointReducer,
});
