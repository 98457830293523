// @flow
type TPayload = any;
type TReducer<TState> = (state: TState, payload: TPayload) => TState;
type TReducers<TState> = {
    [string]: TReducer<TState>,
};
type TAction = {
    payload: TPayload,
    type: string,
};

/**
 * Создает редьюсер для redux
 * Редьюсер для обработки example
 * @param initialState Начальное состояние
 * @param reducers Набор редьюсеров
 * @returns state
 */
export const createReducer = <TState>(initialState: TState, reducers: TReducers<TState>) => (
    state: TState = initialState,
    {payload, type}: TAction
) => {
    const reducer: TReducer<TState> = reducers[type];

    if (reducer) {
        return reducer(state, payload);
    }

    return state;
};
