// @flow
import React from 'react';

type TProps = {
    children: React$Node,
    disabled?: boolean,
    onClick?: () => void,
    type?: string,
};

/**
 * Компонент кнопки
 * @param props Свойства
 * @returns React-компонент
 */
export const Button = ({children, type = 'button', onClick, disabled}: TProps) => {
    return (
        <button className="optima__button" disabled={disabled} onClick={onClick} type={type}>
            {children}
        </button>
    );
};
