// @flow
import {Button} from 'modules/common/components/Button';
import {Map} from 'modules/common/components/Map';
import {Modal} from 'modules/common/components/Modal';
import {isNil} from 'modules/common/helpers/type-check';
import {useAddressGeolocationManage} from 'modules/order/hooks/use-address';
import type {TMapCoordinates} from 'modules/order/hooks/use-order-form-state';
import {selectAddressGeolocationStatus, selectAddressGeolocFirst} from 'modules/order/selectors/address';
import type {TAddress} from 'modules/order/types/address';
import {DATA_STATUSES} from 'modules/redux/data-statuses';
import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

type TProps = {
    closeModalCancel: () => void,
    closeModalChosen: (?TMapCoordinates, ?TAddress) => void,
    initialMapCoordinates: ?TMapCoordinates,
    isModalVisible: boolean,
};

/** Центр карты по умолчанию*/
const MAP_CENTER = [56.49, 84.97];

/**
 * Проверяет корректность адреса
 * @param address адрес из dadata
 * @returns boolean корректный ли адрес
 */
const isAddressCorrect = (address: ?TAddress) =>
    address && address.data && !isNil(address.data.house) && !isNil(address.data.city) && !isNil(address.data.street);

/**
 * Компонент модального окна с картой для формы заказа
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderFormModal = ({isModalVisible, closeModalCancel, closeModalChosen, initialMapCoordinates}: TProps) => {
    const [mapCoordinates, setMapCoordinates] = useState<?TMapCoordinates>();
    const {clearAddressGeolocationList, loadAddressGeolocationList} = useAddressGeolocationManage();
    const geolocAddress = useSelector(selectAddressGeolocFirst);
    const geolocStatus = useSelector(selectAddressGeolocationStatus);

    useEffect(() => {
        setMapCoordinates(initialMapCoordinates);
        if (initialMapCoordinates) {
            loadAddressGeolocationList(initialMapCoordinates[0], initialMapCoordinates[1]);
        }
    }, [loadAddressGeolocationList, initialMapCoordinates, setMapCoordinates]);

    const handleMapClick = useCallback(
        (e: any) => {
            const newCoordinates: TMapCoordinates = e.get('coords');
            setMapCoordinates(newCoordinates);
            clearAddressGeolocationList();
            loadAddressGeolocationList(newCoordinates[0], newCoordinates[1]);
        },
        [clearAddressGeolocationList, loadAddressGeolocationList, setMapCoordinates]
    );

    const balloonMessage = useMemo(() => {
        let message = 'Не удалось определить адресс, выберите другую точку';
        if (geolocStatus === DATA_STATUSES.LOADING) {
            message = 'Данные загружаются';
        } else if (geolocAddress) {
            if (isAddressCorrect(geolocAddress)) {
                message = geolocAddress.value;
            } else {
                message = `Адрес неполный, выберите другую точку. <br>Сейчас определено: ${geolocAddress.value}`;
            }
        }

        return message;
    }, [geolocStatus, geolocAddress]);

    const handleCloseCancel = useCallback(() => {
        setMapCoordinates(null);
        clearAddressGeolocationList();
        closeModalCancel();
    }, [clearAddressGeolocationList, closeModalCancel]);

    const handleCloseChosen = useCallback(() => {
        setMapCoordinates(null);
        closeModalChosen(mapCoordinates, geolocAddress);
        clearAddressGeolocationList();
    }, [geolocAddress, clearAddressGeolocationList, mapCoordinates, closeModalChosen]);

    return (
        <Modal onClose={handleCloseCancel} visible={isModalVisible}>
            <div className="optima__form-modal-content">
                <Map balloonMessage={balloonMessage} handleMapClick={handleMapClick} mapCenter={MAP_CENTER} mapCoordinates={mapCoordinates} />
                <div className="optima__form-modal-button">
                    <Button disabled={!isAddressCorrect(geolocAddress)} onClick={handleCloseChosen}>
                        Подтвердить выбор
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
