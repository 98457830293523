// @flow
import cn from 'classnames';
import {OrderPickupChooser} from 'modules/order/components/OrderPickupChooser';
import type {TPickupPoint} from 'modules/order/types/pickup-point';
import React, {useCallback} from 'react';

type TProps = {
    id: string,
    item: TPickupPoint,
    name?: string,
    value: string,
};

/** Список номеров дней недели*/
const dayNumberList = ['1', '2', '3', '4', '5', '6', '7'];

/** Объект с именами дней недели*/
const dayNumberNameData = {
    '1': 'Понедельник',
    '2': 'Вторник',
    '3': 'Среда',
    '4': 'Четверг',
    '5': 'Пятница',
    '6': 'Суббота',
    '7': 'Воскресенье',
};

/**
 * Компонент итема самовывоза
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderPickupItemDefault = ({item, id, name, value}: TProps) => {
    const renderSchedule = useCallback(() => {
        return dayNumberList.map((dayNumber) => {
            if (!item.schedule[dayNumber] || (item.schedule[dayNumber] && !item.schedule[dayNumber].length)) {
                return null;
            }

            const timeIntervalList = [<p className="optima__pickup-item-day-name" key="dayName">{`${dayNumberNameData[dayNumber]}:`}</p>];

            item.schedule[dayNumber].forEach((scheduleItem, index) => {
                timeIntervalList.push(
                    <p className="optima__pickup-item-time-interval" key={`${item.name}${dayNumber}${index}`}>
                        {scheduleItem.from} - {scheduleItem.to}
                    </p>
                );
            });
            return timeIntervalList;
        });
    }, [item]);

    return (
        <div className="optima__pickup-item">
            <div className="optima__pickup-item-header">
                <div>
                    <h5 className={cn('optima__pickup-item-name', 'f_h5')}>{item.name}</h5>
                    <p className="optima__pickup-item-address">{item.address}</p>
                </div>
                <OrderPickupChooser id={id} name={name} value={value} />
            </div>
            {renderSchedule()}
        </div>
    );
};
