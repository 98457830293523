// @flow
import {addDays, format} from 'date-fns';
import locale from 'date-fns/locale/ru';
import {DATE_SHORT_BACK_FORMAT} from 'modules/common/constants/date-time';

type TDateInfo = {
    date: ?Date,
    toFormat: string,
};

/**
 * Форматирует дату в формат toFormat
 * @param date дата
 * @param toFormat формат в который надо перевести
 * @returns {string} отформатированная строка с датой
 */
export function formatDate({date, toFormat}: TDateInfo) {
    if (!date) {
        return null;
    }

    try {
        return format(date, toFormat, {locale});
    } catch (e) {
        console.warn(e.message);
        return null;
    }
}

/**
 * Генерирует список из dateLimit дат с даты fromDate
 * @param dateLimit лимит дат
 * @param fromDate начальная дата
 * @returns {string[]} Списко дат в формате бэка
 */
export function getDateList(dateLimit: number, fromDate: Date = new Date()): Array<string> {
    const dateList = [];
    let currentDate = fromDate;

    for (let i = 0; i < dateLimit; i++) {
        const formattedDate = formatDate({date: currentDate, toFormat: DATE_SHORT_BACK_FORMAT});
        if (formattedDate) {
            dateList.push(formattedDate);
        }
        currentDate = addDays(currentDate, 1);
    }

    return dateList;
}
