// @flow
import {getDateList} from 'modules/common/helpers/date-time';
import {selectConfigData} from 'modules/common/selectors/config';
import {clearOrderReceiving, getOrderReceivingList} from 'modules/order/actions/order-receiving';
import type {TCoordinates} from 'modules/order/hooks/use-order-form-state';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/**
 * Загружает варианты доставки при валидных данных
 * @param coordinates координаты точки
 */
export function useOrderReceivingListLoad(coordinates: TCoordinates) {
    const dispatch = useDispatch();
    const {dateLimit} = useSelector(selectConfigData);

    useEffect(() => {
        const {lat, lon} = coordinates;

        if (lat && lon) {
            const dateList = getDateList(dateLimit);
            dispatch(
                getOrderReceivingList({
                    date: dateList,
                    lat: Number(lat),
                    lon: Number(lon),
                })
            );
        }
    }, [dateLimit, dispatch, coordinates]);
}

/**
 * Возвращает функцию, которая позволяет очищать список способов доставки
 * @returns {Function} функция
 */
export function useOrderReceivingClear() {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearOrderReceiving());
    }, [dispatch]);
}
