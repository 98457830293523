// @flow
import {DadataService} from 'modules/common/services/dadata';
import type {TAddressGeolocationQuery, TAddressListResponse, TAddressSuggestQuery} from 'modules/order/types/address';

/**
 * Сервис для данных адресов
 */
export class AddressApi {
    /**
     * Сервис для отправки HTTP запросов для dadata
     */
    _dadataService: DadataService;

    /**
     * @param dadataService Сервис для отправки HTTP запросов
     */
    constructor(dadataService: DadataService) {
        this._dadataService = dadataService;
    }

    /**
     * Загружает список предложений автодополнений адресов
     * @param body тело запроса
     * @returns данные
     */
    getSuggestList(body: TAddressSuggestQuery) {
        return this._dadataService.request<TAddressListResponse>('POST', '/suggest/address', {body});
    }

    /**
     * Загружает список предложений адресов из координат
     * @param query параметры запроса
     * @returns данные
     */
    getGeolocateAddress(query: TAddressGeolocationQuery) {
        return this._dadataService.request<TAddressListResponse>('GET', '/geolocate/address', {query});
    }
}
