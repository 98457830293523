// @flow
/**
 * Трансформирует список с id и дату в массив сущносетй
 * @param data объект с данными сущностей
 * @param idList список с id сущностей
 * @returns список с объктами данных сущности
 */
export function getListFromData(data: any, idList: number[] | string[]) {
    return idList.map<any>((id) => data[id]);
}
