// @flow
import {selectConfigData} from 'modules/common/selectors/config';
import {OrderDeliveryForm} from 'modules/order/components/OrderDeliveryForm';
import {OrderFormModal} from 'modules/order/components/OrderFormModal';
import {OrderPickupForm} from 'modules/order/components/OrderPickupForm';
import {OrderTypeItem} from 'modules/order/components/OrderTypeItem';
import {ORDER_TYPE_LIST} from 'modules/order/constants';
import {useOrderFormState} from 'modules/order/hooks/use-order-form-state';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

/**
 * Форма заказа
 * @returns React-компонент
 */
export const OrderForm = () => {
    const config = useSelector(selectConfigData);
    const {
        address,
        addressValue,
        closeModalCancel,
        closeModalChosen,
        coordinates,
        date,
        deliveryVariantId,
        deliveryVariantItem,
        handleAddressChange,
        handleDateChange,
        handleDeliveryVariantChange,
        handleTypeChange,
        handleTimeItemChange,
        isModalVisible,
        mapCoordinates,
        openModal,
        setAddressValue,
        timeItemIndex,
        type,
    } = useOrderFormState();

    const renderTypes = useCallback(() => {
        return ORDER_TYPE_LIST.map((item, index) => (
            <OrderTypeItem
                checked={item.value === type}
                id={item.id}
                index={index}
                key={item.id}
                label={item.label}
                name="optima-type"
                onChange={handleTypeChange}
                value={item.value}
            />
        ));
    }, [handleTypeChange, type]);

    const renderFormTypeContent = useCallback(() => {
        switch (type) {
            case ORDER_TYPE_LIST[0].value:
                return (
                    <OrderDeliveryForm
                        address={address}
                        addressValue={addressValue}
                        coordinates={coordinates}
                        date={date}
                        deliveryVariantId={deliveryVariantId}
                        deliveryVariantItem={deliveryVariantItem}
                        handleAddressChange={handleAddressChange}
                        handleDateChange={handleDateChange}
                        handleDeliveryVariantChange={handleDeliveryVariantChange}
                        handleTimeItemChange={handleTimeItemChange}
                        openModal={openModal}
                        setAddressValue={setAddressValue}
                        timeItemIndex={timeItemIndex}
                    />
                );
            case ORDER_TYPE_LIST[1].value:
                return <OrderPickupForm cityId={config.cityId} />;
            default:
                return null;
        }
    }, [
        address,
        addressValue,
        coordinates,
        config,
        handleAddressChange,
        openModal,
        setAddressValue,
        deliveryVariantItem,
        date,
        type,
        deliveryVariantId,
        handleDeliveryVariantChange,
        timeItemIndex,
        handleTimeItemChange,
        handleDateChange,
    ]);

    return (
        <div className="optima__form">
            <div className="optima__form-types">{renderTypes()}</div>
            {renderFormTypeContent()}
            <OrderFormModal
                closeModalCancel={closeModalCancel}
                closeModalChosen={closeModalChosen}
                initialMapCoordinates={mapCoordinates}
                isModalVisible={isModalVisible}
            />
        </div>
    );
};
