// @flow
import {CalendarNavbar} from 'modules/common/components/CalendarNavbar';
import {Input} from 'modules/common/components/Input';
import {DATE_FORMAT_DATE_PICKER, DATE_SHORT_BACK_FORMAT} from 'modules/common/constants/date-time';
import React, {useCallback} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {formatDate, parseDate} from './format-and-parse-utils';
import {formatDay, formatMonthTitle, formatWeekdayLong, formatWeekdayShort, getFirstDayOfWeek, getMonths} from './locale-utils';
import './styles.less';

type TProps = {
    allowDateList: string[],
    name?: ?string,
    onChange?: (Date, ?any) => void,
    placeholder?: string,
    value?: ?Date,
};

/**
 * Функция заглушка, нужна чтобы скрыть часть компонента
 * @returns {null} ничего
 */
const stub = () => null;

const localeUtils = {
    formatDay,
    formatMonthTitle,
    formatWeekdayLong,
    formatWeekdayShort,
    getFirstDayOfWeek,
    getMonths,
};

/**
 * Календарь
 * @param props Свойства
 * @returns React-компонент
 */
export const Calendar = ({allowDateList, onChange, name, placeholder, value}: TProps) => {
    const handleChange = useCallback(
        (newValue) => {
            if (onChange) {
                onChange(newValue, name);
            }
        },
        [onChange, name]
    );

    const checkDateDisabled = useCallback(
        (date: Date) => {
            return !allowDateList.includes(formatDate(date, DATE_SHORT_BACK_FORMAT));
        },
        [allowDateList]
    );

    return (
        <DayPickerInput
            component={Input}
            dayPickerProps={{
                captionElement: stub,
                disabledDays: checkDateDisabled,
                firstDayOfWeek: 1,
                locale: 'ru',
                localeUtils,
                navbarElement: <CalendarNavbar />,
            }}
            format={DATE_FORMAT_DATE_PICKER}
            formatDate={formatDate}
            inputProps={{name, placeholder, readOnly: true}}
            onDayChange={handleChange}
            parseDate={parseDate}
            value={value}
        />
    );
};
