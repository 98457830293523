// @flow
import {ReactComponent as CrossIcon} from 'modules/common/icons/cross.svg';
import Dialog from 'rc-dialog';
import React from 'react';
import 'rc-dialog/assets/index.css';

type TProps = {
    children?: React$Node,
    onClose: () => void,
    visible: boolean,
};

/**
 * Компонент модального окна
 * @param props Свойства
 * @returns React-компонент
 */
export const Modal = ({children, onClose, visible}: TProps) => {
    return (
        <Dialog
            className="optima__modal"
            closeIcon={<CrossIcon className="icon-default" />}
            onClose={onClose}
            visible={visible}
            wrapClassName="optima__modal-wrap"
        >
            {children}
        </Dialog>
    );
};
