// @flow
import React from 'react';

type TProps = {
    checked: boolean,
    disabled: boolean,
    from: string,
    id: string,
    name: string,
    onChange: (SyntheticInputEvent<HTMLInputElement>) => void,
    to: string,
    value: string,
};

/**
 * Компонент итема времени доставки
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderDeliveryTimeItem = ({checked, disabled, name, onChange, value, id, from, to}: TProps) => {
    return (
        <div className="optima__delivery-time-item">
            <input
                checked={checked}
                className="optima__delivery-time-item-input"
                disabled={disabled}
                id={id}
                name={name}
                onChange={onChange}
                type="radio"
                value={value}
            />
            <label className="optima__delivery-time-item-label" htmlFor={id}>
                <span>{`${from}-${to}`}</span>
            </label>
        </div>
    );
};
