// @flow
import trottle from 'lodash.throttle';
import {SuggestItem} from 'modules/common/components/SuggestItem';
import React, {useCallback, useMemo, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.less';

export type TSuggestionItem = {
    value: string,
    [key: string]: any,
};

type TProps = {
    clearSuggestions: () => void,
    getSuggestions: (string) => void,
    getSuggestionValue: (TSuggestionItem) => string,
    inputValue: string,
    placeholder?: string,
    setActiveItem: (?TSuggestionItem) => void,
    setInputValue: (string) => void,
    setIsBlurred: (boolean) => void,
    suggestionList: any[],
};

/**
 * Рендерит итем предложения для инпута с автозаполнением
 * @param suggestion информация об итеме предложения
 * @returns {SuggestItem} итем предложения
 */
function renderSuggestion(suggestion: TSuggestionItem) {
    return <SuggestItem content={suggestion.value} />;
}

/**
 * Компонент инпута с автодополнением.
 * @returns React-компонент
 */
export const Suggest = ({
    suggestionList,
    setActiveItem,
    getSuggestions,
    clearSuggestions,
    getSuggestionValue,
    inputValue,
    setInputValue,
    placeholder,
    setIsBlurred,
}: TProps) => {
    const [isSelected, setIsSelected] = useState();

    const onChange = useCallback(
        (_, {newValue}) => {
            setInputValue(newValue);
            setIsSelected(false);
        },
        [setInputValue]
    );

    const trottledGetSuggestions = useMemo(() => {
        return trottle(getSuggestions, 900);
    }, [getSuggestions]);

    const onSuggestionsFetchRequested = useCallback(
        ({value}) => {
            trottledGetSuggestions(value);
        },
        [trottledGetSuggestions]
    );

    const onSuggestionsClearRequested = useCallback(() => {
        clearSuggestions();
    }, [clearSuggestions]);

    const onSuggestionSelected = useCallback(
        (_, info) => {
            setActiveItem(info.suggestion);
            setIsSelected(true);
        },
        [setActiveItem]
    );

    const handleBlur = useCallback(() => {
        setIsBlurred(true);

        if (!isSelected) {
            setActiveItem(null);
        }

        setIsSelected(true);
    }, [isSelected, setIsBlurred, setActiveItem]);

    return (
        <Autosuggest
            getSuggestionValue={getSuggestionValue}
            inputProps={{
                onBlur: handleBlur,
                onChange,
                placeholder,
                value: inputValue,
            }}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            renderSuggestion={renderSuggestion}
            suggestions={suggestionList}
        />
    );
};
