// @flow
import type {HttpService} from 'modules/common/services/http';
import type {TPickupPointListQuery, TPickupPointResponse} from 'modules/order/types/pickup-point';

/**
 * Сервис для данных пунктов самовывоза
 */
export class PickupPointApi {
    /**
     * Сервис для отправки HTTP запросов
     */
    _http: HttpService;

    /**
     * @param http Сервис для отправки HTTP запросов
     */
    constructor(http: HttpService) {
        this._http = http;
    }

    /**
     * Загружает список данных пунктов самовывоза
     * @param query параметры запроса
     * @returns данные
     */
    getList(query: TPickupPointListQuery) {
        return this._http.get<TPickupPointResponse>('/api/v1/pickup-point/variants', {query});
    }
}
