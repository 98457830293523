// @flow
/**
 * Проверяет, является ли передеанная переменная объектом
 * @param el проверяемая переменная
 * @returns {boolean} результат проверки
 */
export function isObject(el: any) {
    return 'object' === typeof el && null !== el;
}

/**
 * Проверяет, является значение валидным числом
 * @param value проверяемое значение
 * @returns {boolean} результат проверки
 */
export function isNumber(value: any): %checks {
    return 'number' === typeof value && isFinite(value);
}

/**
 * Проверяет, является значение null или undefined
 * @param value проверяемое значение
 * @returns {boolean} результат проверки
 */
export function isNil(value: any): %checks {
    return value === undefined || null === value;
}
