// @flow
import type {TPickupPoint} from 'modules/order/types/pickup-point.js';
import React from 'react';
import type {THandleTabChoose, TTabInfo} from 'types';

type TPickupPointElementProps = {
    id: string,
    item: TPickupPoint,
    name: string,
    value: string,
};

type THandleChooseTabContext = {
    callback: ?THandleTabChoose,
    list: ?(TTabInfo[]),
};

export type TPickupPointRenderFunction = (TPickupPointElementProps) => HTMLElement;

export const RenderPickupPointContext: React$Context<?TPickupPointRenderFunction> = React.createContext(null);

export const HandleChooseTabContext: React$Context<?THandleChooseTabContext> = React.createContext(null);
