// @flow
import {COMMON_MODULE_NAME} from 'modules/common/constants';
import {commonReducers} from 'modules/common/reducers';
import {ORDER_MODULE_NAME} from 'modules/order/constants';
import {orderReducers} from 'modules/order/reducers';
import {combineReducers} from 'redux';

export const rootReducer = combineReducers({
    [COMMON_MODULE_NAME]: commonReducers,
    [ORDER_MODULE_NAME]: orderReducers,
});
