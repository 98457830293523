// @flow
import {RootContainer} from 'app/components/Root';
import type {TPickupPointRenderFunction} from 'app/context';
import {RenderPickupPointContext, HandleChooseTabContext} from 'app/context';
import {createAppStore} from 'app/store';
import {setConfig} from 'modules/common/actions/config';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import type {TAppConfig, THandleTabChoose, TTabInfo} from 'types';
import './styles/index.less';

type TWidgetProps = {|
    config: TAppConfig,
    onChooseTab?: THandleTabChoose,
    renderPickupPoint?: ?TPickupPointRenderFunction,
    tabsInfoList?: TTabInfo[],
|};

/**
 * Рендерит виджет в html элемент
 * @param element элемента, в который надо отрендерить виджет
 * @param props конфиг виджета c функцией рендера
 */
export function renderOptimaDeliveryWidget(element: ?HTMLElement, props: TWidgetProps) {
    if (!element) {
        throw Error('Cannot find render element');
    }

    if (!props.config) {
        throw Error('Cannot find config');
    }

    const store = createAppStore(props.config);

    store.dispatch(setConfig(props.config));

    /**
     * Рендер компонента в DOM
     * @param App Компонент
     */
    function render(App) {
        ReactDOM.render(
            <Provider store={store}>
                <HandleChooseTabContext.Provider value={{callback: props.onChooseTab, list: props.tabsInfoList}}>
                    <RenderPickupPointContext.Provider value={props.renderPickupPoint}>
                        <App />
                    </RenderPickupPointContext.Provider>
                </HandleChooseTabContext.Provider>
            </Provider>,
            element
        );
    }

    render(RootContainer);

    if (module.hot) {
        module.hot.accept('app/components/Root', () => render(RootContainer));
    }
}
