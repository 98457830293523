// @flow
import type {TStatuses} from 'modules/redux/data-statuses';
import {DATA_STATUSES} from 'modules/redux/data-statuses';
import React from 'react';

type TProps = {
    children: React$Node,
    status: ?TStatuses,
};

/**
 * Компонент для отображения состояния загрузки: в загрузка/успешное/несупешное
 * @param children Успешное состояние
 * @param status Статус загрузки
 * @returns React-компонент
 */
export const LoadingStatus = ({children, status}: TProps) => {
    switch (status) {
        case DATA_STATUSES.LOADING:
            return <p className="optima__loading-status">Загрузка ...</p>;
        case DATA_STATUSES.UN_TOUCHED:
            return null;
        case DATA_STATUSES.ERROR:
            return <p className="optima__loading-status">Ошибка. Не удалось загрузить данные.</p>;
        default:
            return children;
    }
};
