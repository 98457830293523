// @flow
import cn from 'classnames';
import {Suggest} from 'modules/common/components/Suggest';
import {getSuggestionValue} from 'modules/common/helpers/suggest';
import {useAddressSuggestionsManage} from 'modules/order/hooks/use-address';
import type {TCoordinates} from 'modules/order/hooks/use-order-form-state';
import {selectAddressSuggestionList} from 'modules/order/selectors/address';
import type {TAddress} from 'modules/order/types/address';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

type TProps = {
    address: ?TAddress,
    coordinates: TCoordinates,
    handleAddressChange: (?TAddress) => void,
    setIsAddressBlurred: (boolean) => void,
    setValue: (string) => void,
    value: string,
};

/**
 * Компонент адреса
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderAddressControl = ({address, value, coordinates, handleAddressChange, setValue, setIsAddressBlurred}: TProps) => {
    const {clearAddressSuggestionList, loadAddressSuggestionList} = useAddressSuggestionsManage();
    const suggestingList = useSelector(selectAddressSuggestionList);

    const fullAddress = useMemo(() => {
        if (address) {
            return address.unrestricted_value;
        }
        return '';
    }, [address]);

    return (
        <div className={cn('optima__form-item', 'optima__address-control')}>
            <Suggest
                clearSuggestions={clearAddressSuggestionList}
                getSuggestionValue={getSuggestionValue}
                getSuggestions={loadAddressSuggestionList}
                inputValue={value}
                placeholder="Адрес"
                setActiveItem={handleAddressChange}
                setInputValue={setValue}
                setIsBlurred={setIsAddressBlurred}
                suggestionList={suggestingList}
            />
            <input name="optima-lat" type="hidden" value={coordinates.lat || ''} />
            <input name="optima-lon" type="hidden" value={coordinates.lon || ''} />
            <input name="optima-address" type="hidden" value={fullAddress} />
        </div>
    );
};
