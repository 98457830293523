// @flow
import {selectConfigData} from 'modules/common/selectors/config';
import type {TMapCoordinates} from 'modules/order/hooks/use-order-form-state';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Map as YMap, Placemark, YMaps} from 'react-yandex-maps';

type TProps = {
    balloonMessage: string,
    handleMapClick: (any) => void,
    mapCenter: TMapCoordinates,
    mapCoordinates: ?TMapCoordinates,
    zoom?: number,
};
/**
 * Функция авто открытия балуна при выборе точки на карте
 * @param ref что-то вроде рефа на балун яндекса.
 */
const autoOpenBalloon = (ref: any) => {
    if (ref) {
        ref.balloon.open();
    }
};

/** Параметры балуна по умолчанию */
const defaultBalloonOption = {
    balloonOffset: [3, -20],
    hideIconOnBalloonOpen: false,
};

/**
 * Компонент карты
 * @param props Свойства
 * @returns React-компонент
 */
export const Map = ({handleMapClick, mapCenter, zoom = 12, mapCoordinates, balloonMessage}: TProps) => {
    const config = useSelector(selectConfigData);

    const renderMapDot = useCallback(() => {
        if (mapCoordinates && mapCoordinates.length) {
            return (
                <Placemark
                    geometry={mapCoordinates}
                    instanceRef={autoOpenBalloon}
                    modules={['geoObject.addon.balloon']}
                    options={defaultBalloonOption}
                    properties={{
                        balloonContent: `<p>${balloonMessage}</p>`,
                    }}
                />
            );
        }
        return null;
    }, [balloonMessage, mapCoordinates]);

    const query = useMemo(() => {
        return {apikey: config.yandexMapKey, lang: 'ru_RU'};
    }, [config]);

    return (
        <YMaps query={query}>
            <YMap className="optima__form-modal-map" defaultState={{center: mapCenter, controls: [], zoom}} onClick={handleMapClick}>
                {renderMapDot()}
            </YMap>
        </YMaps>
    );
};
