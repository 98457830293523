// @flow
import {toFormData} from 'modules/common/helpers/form-data';
import queryString from 'query-string';
import type {TApiResponseNotice, TApiResponseError, TGenApiResponse} from 'types';

export type TRequestQueryParameters = $ReadOnly<{
    [string]: string | number | $ReadOnlyArray<string | number> | null | void,
}>;

export type TRequestBody = $ReadOnly<{
    [string]: any,
}>;

type TRequestExtraOptions = $ReadOnly<{
    dataType?: 'JSON' | 'FORM_DATA',
    isPrivate?: boolean,
}>;

type TRequestParams = $ReadOnly<{|
    body?: TRequestBody,
    extraOptions?: TRequestExtraOptions,
    options?: $ReadOnly<RequestOptions>,
    query?: TRequestQueryParameters,
|}>;

/**
 * Сервис для отправки HTTP запросов
 */
export class HttpService {
    /**
     * Адрес хоста, на который отправляются запросы
     */
    _host: string;

    /**
     * Режим запроса
     * @see https://developer.mozilla.org/ru/docs/Web/API/Request/mode
     */
    _mode: 'cors' | 'no-cors' | 'same-origin';

    /**
     * Ключ клиента для авторизации
     */
    _clientKey: string;

    /**
     * @param host Адрес хоста, на который отправляются запросы
     * @param clientKey Ключ авторизации клиента
     */
    constructor(host: string, clientKey: string) {
        this._host = this.formatHost(host);
        this._mode = 'cors';
        this._clientKey = clientKey;
    }

    /**
     * Формирует url для запроса
     * @param pathname Часть пути относительно хоста
     * @param queryParams Параметры
     * @returns Полный путь
     */
    getUrl(pathname: string, queryParams: TRequestQueryParameters = {}) {
        const query = queryParams ? queryString.stringify(queryParams, {arrayFormat: 'bracket'}) : null;
        const baseUrl = this._host + pathname;
        return query ? `${baseUrl}?${query}` : baseUrl;
    }

    /**
     * Формирует параметры для запроса
     * @param method Метод запроса
     * @param body Тело запроса
     * @param requestOptions Параметры запроса
     * @param extraRequestOptions Дополнитенльные параметры запроса
     * @returns Параметры запроса
     */
    getOptions(
        method: string,
        body?: TRequestBody,
        requestOptions: $ReadOnly<RequestOptions> = {},
        extraRequestOptions: $ReadOnly<TRequestExtraOptions> = {dataType: 'JSON', isPrivate: true}
    ) {
        const options: RequestOptions = {
            method,
            mode: this._mode,
            ...requestOptions,
        };

        options.headers = this.getHeaders(requestOptions.headers, extraRequestOptions.isPrivate);

        if (body) {
            options.body = this.getBody(body, extraRequestOptions.dataType);
        }

        return options;
    }

    /**
     * Формирует заголовки запроса
     * @param headersObj Заголовки запроса
     * @param isPrivate Флаг, обозначающий необходимость добавлять заголовок Authorization
     * @returns Заголовки запроса
     */
    getHeaders(headersObj?: HeadersInit, isPrivate?: boolean) {
        const headers = new Headers(headersObj);

        if (isPrivate) {
            headers.set('X-Client-Key', this._clientKey);
        }

        return headers;
    }

    /**
     * Формирует тело запроса
     * @param body тело запроса
     * @param dataType тип тела запроса
     * @returns Отформатированное тело запроса
     */
    getBody(body: TRequestBody, dataType?: string) {
        if ('FORM_DATA' === dataType) {
            return toFormData(body);
        }

        return JSON.stringify(body);
    }

    /**
     * Форматирует адрес хоста, убирая лишние символы
     * @param host Адрес хоста
     * @returns Адрес хоста
     */
    formatHost(host: string) {
        const hostTrimmed = host.trim();
        return hostTrimmed.endsWith('/') ? hostTrimmed.slice(0, -1) : hostTrimmed;
    }

    /**
     * Отправляет запрос
     * @param method Метод запроса
     * @param pathname Часть пути относительно хоста
     * @param requestParams Параметры запроса
     * @returns Результат запроса
     */
    async request<T>(
        method: string,
        pathname: string,
        {body, extraOptions, options, query}: TRequestParams = {}
    ): Promise<TGenApiResponse<T, TApiResponseError, TApiResponseNotice>> {
        const path = this.getUrl(pathname, query);
        const requestOptions = this.getOptions(method, body, options, extraOptions);

        try {
            const response = await fetch(path, requestOptions);
            return await response.json();
        } catch (error) {
            return {
                data: {},
                errors: [
                    {
                        code: 0,
                        data: [],
                        detail: '',
                        title: 'fetch or parse json failed',
                    },
                ],
                notice: [],
            };
        }
    }

    /**
     * Отправляет GET запрос
     * @param pathname Часть пути относительно хоста
     * @param requestParams Параметры запроса
     * @returns Результат запроса
     */
    async get<T>(pathname: string, requestParams?: TRequestParams): Promise<TGenApiResponse<T, TApiResponseError, TApiResponseNotice>> {
        return this.request<T>('GET', pathname, requestParams);
    }

    /**
     * Отправляет POST запрос
     * @param pathname Часть пути относительно хоста
     * @param requestParams Параметры запроса
     * @returns Результат запроса
     */
    async post<T>(pathname: string, requestParams?: TRequestParams): Promise<TGenApiResponse<T, TApiResponseError, TApiResponseNotice>> {
        return this.request<T>('POST', pathname, requestParams);
    }
}
