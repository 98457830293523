// @flow
import cn from 'classnames';
import {getYear} from 'date-fns';
import {ReactComponent as CalendarControlIcon} from 'modules/common/icons/down-arrow.svg';
import React, {useCallback, useMemo} from 'react';

type TProps = {
    localeUtils?: {
        getMonths: () => Array<string>,
    },
    month?: Date,
    onNextClick?: () => any,
    onPreviousClick?: () => any,
    previousMonth?: Date,
};

/**
 * Хедер с навигацией и датой для календаря
 * @param {object} localeUtils - вспомогательные функции
 * @param {Date} month - текущий месяц
 * @param {function} onNextClick - обработчик нажатия на кнопку "следующий месяц"
 * @param {function} onPreviousClick - обработчик нажатия на кнопку "предыдущий месяц"
 * @param {Date} previousMonth - предыдущий месяц
 * @returns React-компонент
 */
export function CalendarNavbar({month, previousMonth, onPreviousClick, onNextClick, localeUtils}: TProps): React$Node {
    const months = useMemo(() => (localeUtils ? localeUtils.getMonths() : []), [localeUtils]);
    const year = useMemo(() => (previousMonth ? getYear(previousMonth) : ''), [previousMonth]);
    const current = useMemo(() => (month ? months[month.getMonth()] : ''), [month, months]);

    const _onPreviousClick = useCallback(() => {
        if (onPreviousClick) {
            onPreviousClick();
        }
    }, [onPreviousClick]);

    const _onNextClick = useCallback(() => {
        if (onNextClick) {
            onNextClick();
        }
    }, [onNextClick]);

    return (
        <div className="optima__calendar-navbar">
            <div className="optima__calendar-navbar-content">
                <div className="optima__calendar-navbar-control">
                    <button className="optima__calendar-navbar-control-button" onClick={_onPreviousClick} type="button">
                        <CalendarControlIcon
                            className={cn('optima__calendar-navbar-control-icon', 'optima__calendar-navbar-control-icon_left', 'icon-default')}
                        />
                    </button>
                </div>
                <div className="optima__calendar-navbar-info">
                    {current} <span className="t-a_e">{year}</span>
                </div>
                <div className="optima__calendar-navbar-control">
                    <button className="optima__calendar-navbar-control-button" onClick={_onNextClick} type="button">
                        <CalendarControlIcon
                            className={cn('optima__calendar-navbar-control-icon', 'optima__calendar-navbar-control-icon_right', 'icon-default')}
                        />
                    </button>
                </div>
            </div>
            <div className="optima__calendar-navbar-separator" />
        </div>
    );
}
