// @flow
import {clearPickupPoint, getPickupPointList} from 'modules/order/actions/pickup-point';
import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';

/**
 * Загружает варианты пунктов самовывоза при валидных данных
 * @param externalId id города
 */
export function usePickupPointListLoad(externalId: ?string) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (externalId) {
            dispatch(
                getPickupPointList({
                    externalId,
                })
            );
        }
    }, [dispatch, externalId]);
}

/**
 * Возвращает функцию, которая позволяет очищать список пунктов самовывоза
 * @returns {Function} функция
 */
export function usePickupPointClear() {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(clearPickupPoint());
    }, [dispatch]);
}
