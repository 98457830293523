// @flow
import React from 'react';

export type TInputProps = {
    autoComplete?: 'on' | 'off',
    name: string,
    onBlur?: (SyntheticEvent<HTMLInputElement>) => void,
    onChange?: (SyntheticEvent<HTMLInputElement>) => void,
    onClick?: (SyntheticEvent<HTMLInputElement>) => void,
    onFocus?: (SyntheticEvent<HTMLInputElement>) => void,
    onKeyDown?: (SyntheticEvent<HTMLInputElement>) => void,
    onKeyUp?: (SyntheticEvent<HTMLInputElement>) => void,
    placeholder: string,
    readOnly?: boolean,
    value?: string,
};

/**
 * Компонент инпут
 * @param props Свойства
 * @returns React-компонент
 */
export const Input = React.forwardRef<TInputProps, HTMLInputElement>(
    ({autoComplete, onBlur, onChange, onClick, onFocus, onKeyDown, onKeyUp, readOnly, value, placeholder, name}: TInputProps, ref) => {
        return (
            <input
                autoComplete={autoComplete}
                className="optima__input"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onClick={onClick}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                placeholder={placeholder}
                readOnly={readOnly}
                ref={ref}
                value={value}
            />
        );
    }
);

Input.displayName = 'Input';
