// @flow
import type {TIdList} from 'modules/common/types';
import {ORDER_RECEIVING_ACTION_TYPE} from 'modules/order/constants';
import type {TDeliveryVariantData, TOrderReceivingListPayload} from 'modules/order/types/order-receiving';
import {DATA_STATUSES, type TStatuses} from 'modules/redux/data-statuses';
import {createReducer} from 'modules/redux/reducer';

export type TActionGetOrdRecListStart = {type: typeof ORDER_RECEIVING_ACTION_TYPE.GET_LIST_START};
export type TActionGetOrdRecListFail = {type: typeof ORDER_RECEIVING_ACTION_TYPE.GET_LIST_FAIL};
export type TActionGetOrdRecClear = {type: typeof ORDER_RECEIVING_ACTION_TYPE.CLEAR};
export type TActionGetOrdRecListSuccess = {payload: TOrderReceivingListPayload, type: typeof ORDER_RECEIVING_ACTION_TYPE.GET_LIST_SUCCESS};

export type TOrderReceivingState = {|
    data: TDeliveryVariantData,
    list: TIdList,
    status: TStatuses,
|};

export const initialState: TOrderReceivingState = {
    data: {},
    list: [],
    status: DATA_STATUSES.UN_TOUCHED,
};

const reducer = {
    [ORDER_RECEIVING_ACTION_TYPE.GET_LIST_START]: (state) => ({
        ...state,
        status: DATA_STATUSES.LOADING,
    }),
    [ORDER_RECEIVING_ACTION_TYPE.GET_LIST_SUCCESS]: (state, payload: TOrderReceivingListPayload) => ({
        ...state,
        data: payload.data,
        list: payload.list,
        status: DATA_STATUSES.SUCCESS,
    }),
    [ORDER_RECEIVING_ACTION_TYPE.GET_LIST_FAIL]: (state) => ({
        ...state,
        status: DATA_STATUSES.ERROR,
    }),
    [ORDER_RECEIVING_ACTION_TYPE.CLEAR]: () => initialState,
};

export const orderReceivingReducer = createReducer(initialState, reducer);
