// @flow
import {PICKUP_POINT_ACTION_TYPE} from 'modules/order/constants';
import {transformPickupPointList} from 'modules/order/helpers/transform';
import type {
    TActionGetPickPointListFail,
    TActionGetPickPointListStart,
    TActionGetPickPointListSuccess,
    TActionClearPickPointList,
} from 'modules/order/reducers/pickup-point';
import type {TPickupPointListQuery} from 'modules/order/types/pickup-point';
import type {TThunkAction} from 'types';

/**
 * @returns экшен
 */
function getPickupPointListFail(): TActionGetPickPointListFail {
    return {
        type: PICKUP_POINT_ACTION_TYPE.GET_LIST_FAIL,
    };
}

/**
 * @returns экшен
 */
function getPickupPointListStart(): TActionGetPickPointListStart {
    return {
        type: PICKUP_POINT_ACTION_TYPE.GET_LIST_START,
    };
}

/**
 * @param payload данные
 * @returns экшен
 */
function getPickupPointListSuccess(payload): TActionGetPickPointListSuccess {
    return {
        payload,
        type: PICKUP_POINT_ACTION_TYPE.GET_LIST_SUCCESS,
    };
}

/**
 * @returns экшен
 */
export function clearPickupPoint(): TActionClearPickPointList {
    return {
        type: PICKUP_POINT_ACTION_TYPE.CLEAR,
    };
}

/**
 * @param query параметры запроса
 * @returns thunk-экшен
 */
export function getPickupPointList(query: TPickupPointListQuery): TThunkAction<Promise<void>> {
    return async (dispatch, getState, {pickupPointApi}) => {
        dispatch(getPickupPointListStart());
        try {
            const pickupPointResponse = await pickupPointApi.getList(query);
            if (pickupPointResponse.errors && !pickupPointResponse.errors.length) {
                dispatch(getPickupPointListSuccess(transformPickupPointList(pickupPointResponse.data)));
            } else {
                dispatch(getPickupPointListFail());
            }
        } catch (e) {
            dispatch(getPickupPointListFail());
        }
    };
}
