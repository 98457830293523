// @flow
import {getListFromData} from 'modules/common/helpers/selectors';
import {isNil} from 'modules/common/helpers/type-check';
import {ORDER_MODULE_NAME} from 'modules/order/constants';
import type {TDeliveryVariantList} from 'modules/order/types/order-receiving';
import {createSelector} from 'reselect';
import type {TRootState} from 'types';

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectModule(state: TRootState) {
    return state[ORDER_MODULE_NAME];
}

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectOrderReceivingState(state: TRootState) {
    return selectModule(state).orderReceiving;
}

/**
 * Селектор сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectOrderReceivingData(state: TRootState) {
    return selectOrderReceivingState(state).data;
}

/**
 * Селектор сущности
 * @param state Глобальный стейт приложения
 * @param id варианта доставки
 * @returns state модуля
 */
export function selectOrderReceivingItem(state: TRootState, id: ?string) {
    if (isNil(id)) {
        return null;
    }

    return selectOrderReceivingData(state)[id];
}

/**
 * Селектор списка id
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectOrderReceivingIdList(state: TRootState) {
    return selectOrderReceivingState(state).list;
}

/**
 * Селектор флага загрузки
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectOrderReceivingStatus(state: TRootState) {
    return selectOrderReceivingState(state).status;
}

/**
 * Селектор списка сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export const selectOrderReceivingList: (state: TRootState) => TDeliveryVariantList = createSelector(
    [selectOrderReceivingData, selectOrderReceivingIdList],
    getListFromData
);

/**
 * Селектор списка доступных дат
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export const selectOrderReceivingDateList: (state: TRootState, id: ?string) => string[] = createSelector(
    [selectOrderReceivingItem],
    (item) => {
        if (!item) {
            return [];
        }

        return Object.keys(item.schedule);
    }
);
