// @flow
import {LoadingStatus} from 'modules/common/components/LoadingStatus';
import {OrderPickupList} from 'modules/order/components/OrderPickupList';
import {usePickupPointListLoad} from 'modules/order/hooks/use-pickup-point';
import {selectPickupPointList, selectPickupPointStatus} from 'modules/order/selectors/pickup-point';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

type TProps = {
    cityId: ?string,
};
/**
 * Форма доставки самовывозом
 * @returns React-компонент
 */
export const OrderPickupForm = ({cityId}: TProps) => {
    const pickupPointStatus = useSelector(selectPickupPointStatus);
    const pickupPointList = useSelector(selectPickupPointList);

    usePickupPointListLoad(cityId);

    const content = useMemo(
        () =>
            cityId ? (
                <LoadingStatus status={pickupPointStatus}>
                    <OrderPickupList items={pickupPointList} name="optima-externalId" />
                </LoadingStatus>
            ) : (
                <p className="optima__pickup-list-empty">Не выбран город</p>
            ),
        [cityId, pickupPointStatus, pickupPointList]
    );

    return <div className="optima__form-item">{content}</div>;
};
