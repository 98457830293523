// @flow
import {ORDER_MODULE_NAME} from 'modules/order/constants';
import type {TRootState} from 'types';

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectModule(state: TRootState) {
    return state[ORDER_MODULE_NAME];
}

/**
 * Селектор модуля
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressState(state: TRootState) {
    return selectModule(state).address;
}

/**
 * Селектор спикска сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressSuggestionList(state: TRootState) {
    return selectAddressState(state).listSuggestion;
}

/**
 * Селектор списка сущностей
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressGeolocationList(state: TRootState) {
    return selectAddressState(state).listGeolocation;
}

/**
 * Селектор первой сущности из списка
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressGeolocFirst(state: TRootState) {
    return selectAddressGeolocationList(state)[0] || null;
}

/**
 * Селектор флага загрузки
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressSuggestionStatus(state: TRootState) {
    return selectAddressState(state).statusSuggestion;
}

/**
 * Селектор флага загрузки
 * @param state Глобальный стейт приложения
 * @returns state модуля
 */
export function selectAddressGeolocationStatus(state: TRootState) {
    return selectAddressState(state).statusGeolocation;
}
