// @flow
import type {TSuggestionItem} from 'modules/common/components/Suggest';

/**
 * Получает значение предложения
 * @param suggestion данные предложения
 * @returns {string} значение предложения
 */
export function getSuggestionValue(suggestion: TSuggestionItem) {
    return suggestion.value;
}
