// @flow
import {RenderPickupPointContext} from 'app/context';
import {OrderPickupItemDefault} from 'modules/order/components/OrderPickupItemDefault';
import {OrderPickupItemHtml} from 'modules/order/components/OrderPickupItemHtml';
import type {TPickupPointList} from 'modules/order/types/pickup-point';
import React, {useCallback, useContext} from 'react';

type TProps = {
    items: TPickupPointList,
    name: string,
};

/**
 * Компонент списка итемов пунктов самовывоза
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderPickupList = ({name, items}: TProps) => {
    const renderItem = useContext(RenderPickupPointContext);

    const renderPickupPointItems = useCallback(() => {
        if (!items.length) {
            return <p className="optima__pickup-list-empty">Отсутствуют доступные варианты</p>;
        }

        const PickupItem = renderItem ? OrderPickupItemHtml : OrderPickupItemDefault;

        return items.map((item) => {
            const idString = String(item.externalId);
            const elementId = `optima_pickup_item_${idString}`;

            return <PickupItem id={elementId} item={item} key={elementId} name={name} value={idString} />;
        });
    }, [renderItem, items, name]);
    return <div className="optima__pickup-list">{renderPickupPointItems()}</div>;
};
