// @flow
export type TDataStatuses = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    UN_TOUCHED: 'UN_TOUCHED',
};

export type TStatuses = $Values<TDataStatuses>;

export const DATA_STATUSES: TDataStatuses = {
    ERROR: 'ERROR',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    UN_TOUCHED: 'UN_TOUCHED',
};
