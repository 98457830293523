// @flow
import {isNumber} from 'modules/common/helpers/type-check';
import {OrderDeliveryTimeItem} from 'modules/order/components/OrderDeliveryTimeItem';
import type {TDeliveryScheduleList} from 'modules/order/types/order-receiving';
import React, {useCallback, useMemo} from 'react';

type TProps = {
    items: TDeliveryScheduleList,
    name: string,
    onChange: (SyntheticInputEvent<HTMLInputElement>) => void,
    value: ?string,
};

/**
 * Компонент списка итемов времени доставки
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderDeliveryTimeList = ({name, onChange, value, items}: TProps) => {
    const renderItems = useCallback(() => {
        if (!items.length) {
            return <p className="optima__delivery-time-list-empty">Отсутствуют доступные варианты</p>;
        }

        return items.map((item, index) => {
            const indexString = String(index);

            return (
                <OrderDeliveryTimeItem
                    checked={indexString === value}
                    disabled={0 === item.limit}
                    from={item.from}
                    id={`delivery-time_${indexString}`}
                    key={`delivery-time_${indexString}`}
                    name={name}
                    onChange={onChange}
                    to={item.to}
                    value={indexString}
                />
            );
        });
    }, [name, items, onChange, value]);

    const timeSlotInfo = useMemo(() => {
        const activeIdNumber = Number(value);
        if (isNumber(activeIdNumber) && items[activeIdNumber]) {
            const activeSlot = items[activeIdNumber];
            return {
                deliveryCost: String(activeSlot.cost),
                externalId: activeSlot.externalId,
                from: activeSlot.from,
                to: activeSlot.to,
                warehouseId: String(activeSlot.warehouseId),
            };
        }
        return {
            deliveryCost: '',
            externalId: '',
            from: '',
            to: '',
            warehouseId: '',
        };
    }, [items, value]);

    return (
        <div className="optima__delivery-time-list">
            {renderItems()}
            <input name="optima-warehouseId" type="hidden" value={timeSlotInfo.warehouseId} />
            <input name="optima-from" type="hidden" value={timeSlotInfo.from} />
            <input name="optima-to" type="hidden" value={timeSlotInfo.to} />
            <input name="optima-deliveryCost" type="hidden" value={timeSlotInfo.deliveryCost} />
            <input name="optima-warehouseExternalId" type="hidden" value={timeSlotInfo.externalId} />
        </div>
    );
};
