// @flow
import {ADDRESS_ACTION_TYPE} from 'modules/order/constants';
import type {TAddressList} from 'modules/order/types/address';
import {DATA_STATUSES, type TStatuses} from 'modules/redux/data-statuses';
import {createReducer} from 'modules/redux/reducer';

export type TActionGetAddrSugListStart = {type: typeof ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_START};
export type TActionGetAddrSugListFail = {type: typeof ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_FAIL};
export type TActionGetAddrSugClear = {type: typeof ADDRESS_ACTION_TYPE.CLEAR_SUGGESTION_LIST};
export type TActionGetAddrSugListSuccess = {payload: TAddressList, type: typeof ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_SUCCESS};
export type TActionGetAddrGeoListStart = {type: typeof ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_START};
export type TActionGetAddrGeoListFail = {type: typeof ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_FAIL};
export type TActionGetAddrGeoClear = {type: typeof ADDRESS_ACTION_TYPE.CLEAR_GEOLOCATION_LIST};
export type TActionGetAddrGeoListSuccess = {payload: TAddressList, type: typeof ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_SUCCESS};

export type TAddressState = {|
    listGeolocation: TAddressList,
    listSuggestion: TAddressList,
    statusGeolocation: TStatuses,
    statusSuggestion: TStatuses,
|};

export const initialState: TAddressState = {
    listGeolocation: [],
    listSuggestion: [],
    statusGeolocation: DATA_STATUSES.UN_TOUCHED,
    statusSuggestion: DATA_STATUSES.UN_TOUCHED,
};

const reducer = {
    [ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_START]: (state) => ({
        ...state,
        statusSuggestion: DATA_STATUSES.LOADING,
    }),
    [ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_SUCCESS]: (state, payload: TAddressList) => ({
        ...state,
        listSuggestion: payload,
        statusSuggestion: DATA_STATUSES.SUCCESS,
    }),
    [ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_FAIL]: (state) => ({
        ...state,
        statusSuggestion: DATA_STATUSES.ERROR,
    }),
    [ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_START]: (state) => ({
        ...state,
        statusGeolocation: DATA_STATUSES.LOADING,
    }),
    [ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_SUCCESS]: (state, payload: TAddressList) => ({
        ...state,
        listGeolocation: payload,
        statusGeolocation: DATA_STATUSES.SUCCESS,
    }),
    [ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_FAIL]: (state) => ({
        ...state,
        statusGeolocation: DATA_STATUSES.ERROR,
    }),
    [ADDRESS_ACTION_TYPE.CLEAR_SUGGESTION_LIST]: (state) => ({...state, listSuggestion: [], statusSuggestion: DATA_STATUSES.UN_TOUCHED}),
    [ADDRESS_ACTION_TYPE.CLEAR_GEOLOCATION_LIST]: (state) => ({...state, listGeolocation: [], statusGeolocation: DATA_STATUSES.UN_TOUCHED}),
};

export const addressReducer = createReducer(initialState, reducer);
