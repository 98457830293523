// @flow
import type {TIdList} from 'modules/common/types';
import {PICKUP_POINT_ACTION_TYPE} from 'modules/order/constants';
import type {TPickupPointData, TPickupPointListPayload} from 'modules/order/types/pickup-point';
import {DATA_STATUSES, type TStatuses} from 'modules/redux/data-statuses';
import {createReducer} from 'modules/redux/reducer';

export type TActionGetPickPointListStart = {type: typeof PICKUP_POINT_ACTION_TYPE.GET_LIST_START};
export type TActionGetPickPointListFail = {type: typeof PICKUP_POINT_ACTION_TYPE.GET_LIST_FAIL};
export type TActionClearPickPointList = {type: typeof PICKUP_POINT_ACTION_TYPE.CLEAR};
export type TActionGetPickPointListSuccess = {payload: TPickupPointListPayload, type: typeof PICKUP_POINT_ACTION_TYPE.GET_LIST_SUCCESS};

export type TPickupPointState = {|
    data: TPickupPointData,
    list: TIdList,
    status: TStatuses,
|};

export const initialState: TPickupPointState = {
    data: {},
    list: [],
    status: DATA_STATUSES.UN_TOUCHED,
};

const reducer = {
    [PICKUP_POINT_ACTION_TYPE.GET_LIST_START]: (state) => ({
        ...state,
        status: DATA_STATUSES.LOADING,
    }),
    [PICKUP_POINT_ACTION_TYPE.GET_LIST_SUCCESS]: (state, payload: TPickupPointListPayload) => ({
        ...state,
        data: payload.data,
        list: payload.list,
        status: DATA_STATUSES.SUCCESS,
    }),
    [PICKUP_POINT_ACTION_TYPE.GET_LIST_FAIL]: (state) => ({
        ...state,
        status: DATA_STATUSES.ERROR,
    }),
    [PICKUP_POINT_ACTION_TYPE.CLEAR]: () => initialState,
};

export const pickupPointReducer = createReducer(initialState, reducer);
