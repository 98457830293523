// @flow
import {CONFIG_ACTION_TYPE} from 'modules/common/constants';
import type {TActionSetConfig} from 'modules/common/reducers/config';
import type {TAppConfig} from 'types';

/**
 * @param payload данные
 * @returns экшен
 */
export function setConfig(payload: TAppConfig): TActionSetConfig {
    return {
        payload,
        type: CONFIG_ACTION_TYPE.SET_CONFIG,
    };
}
