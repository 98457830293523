// @flow
import {isNumber} from 'modules/common/helpers/type-check';
import {clearAddressGeolocList, clearAddressSuggestList, getAddressGeolocList, getAddressSuggestList} from 'modules/order/actions/address';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

/**
 * Загружает предложения адреса при валидных данных
 * @return объект с функциями загрузки и очистки
 */
export function useAddressSuggestionsManage() {
    const dispatch = useDispatch();

    const loadAddressSuggestionList = useCallback(
        (query: ?string) => {
            if (query) {
                dispatch(
                    getAddressSuggestList({
                        query,
                    })
                );
            }
        },
        [dispatch]
    );

    const clearAddressSuggestionList = useCallback(() => {
        dispatch(clearAddressSuggestList());
    }, [dispatch]);

    return {
        clearAddressSuggestionList,
        loadAddressSuggestionList,
    };
}

/**
 * Загружает предложения адреса по координатам на карте
 * @return объект с функциями загрузки и очистки
 */
export function useAddressGeolocationManage() {
    const dispatch = useDispatch();

    const loadAddressGeolocationList = useCallback(
        (lat: ?number, lon: ?number) => {
            if (isNumber(lat) && isNumber(lon)) {
                dispatch(
                    getAddressGeolocList({
                        lat,
                        lon,
                    })
                );
            }
        },
        [dispatch]
    );

    const clearAddressGeolocationList = useCallback(() => {
        dispatch(clearAddressGeolocList());
    }, [dispatch]);

    return {
        clearAddressGeolocationList,
        loadAddressGeolocationList,
    };
}
