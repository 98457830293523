// @flow
import {Button} from 'modules/common/components/Button';
import {Calendar} from 'modules/common/components/Calendar';
import {LoadingStatus} from 'modules/common/components/LoadingStatus';
import {DATE_SHORT_BACK_FORMAT} from 'modules/common/constants/date-time';
import {formatDate} from 'modules/common/helpers/date-time';
import {isNil} from 'modules/common/helpers/type-check';
import type {TOption} from 'modules/common/types';
import {OrderAddressControl} from 'modules/order/components/OrderAddressControl';
import {OrderDeliveryTimeList} from 'modules/order/components/OrderDeliveryTimeList';
import {OrderDeliveryTypeControl} from 'modules/order/components/OrderDeliveryTypeControl';
import type {TCoordinates} from 'modules/order/hooks/use-order-form-state';
import {useOrderReceivingListLoad} from 'modules/order/hooks/use-order-receiving';
import {selectOrderReceivingDateList, selectOrderReceivingList, selectOrderReceivingStatus} from 'modules/order/selectors/order-receiving';
import type {TAddress} from 'modules/order/types/address';
import type {TDeliveryVariant} from 'modules/order/types/order-receiving';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

type TProps = {
    address: ?TAddress,
    addressValue: string,
    coordinates: TCoordinates,
    date: ?Date,
    deliveryVariantId: ?string,
    deliveryVariantItem: ?TDeliveryVariant,
    handleAddressChange: (?TAddress) => void,
    handleDateChange: (?Date) => void,
    handleDeliveryVariantChange: (?string) => void,
    handleTimeItemChange: (SyntheticEvent<HTMLInputElement>) => void,
    openModal: () => void,
    setAddressValue: (string) => void,
    timeItemIndex: ?string,
};

/**
 * Проверяет корректность адреса и наличия коррдинат
 * @param coordinates координаты куда везти
 * @param address определенный адрес
 * @returns boolean некорректный ли адрес
 */
const isAddressIncorrect = (coordinates: TCoordinates, address: ?TAddress) =>
    !address ||
    (address &&
        address.data &&
        (isNil(coordinates.lat) || isNil(coordinates.lon) || isNil(address.data.house) || isNil(address.data.city) || isNil(address.data.street)));

/**
 * Форма курьерской доставки
 * @returns React-компонент
 */
export const OrderDeliveryForm = ({
    address,
    coordinates,
    handleAddressChange,
    setAddressValue,
    addressValue,
    openModal,
    deliveryVariantItem,
    handleDateChange,
    deliveryVariantId,
    handleDeliveryVariantChange,
    date,
    handleTimeItemChange,
    timeItemIndex,
}: TProps) => {
    const orderReceivingStatus = useSelector(selectOrderReceivingStatus);
    const deliveryVariantList = useSelector(selectOrderReceivingList);
    const deliveryVariantDateList = useSelector((state) => selectOrderReceivingDateList(state, deliveryVariantId));

    useOrderReceivingListLoad(coordinates);

    const [isAddressBlurred, setIsAddressBlurred] = useState();

    const deliveryVariantTimeList = useMemo(() => {
        const formattedDate = formatDate({date, toFormat: DATE_SHORT_BACK_FORMAT});

        if (!deliveryVariantItem || isNil(formattedDate) || (deliveryVariantItem && !deliveryVariantItem.schedule[formattedDate])) {
            return [];
        }
        return deliveryVariantItem.schedule[formattedDate];
    }, [deliveryVariantItem, date]);

    const deliverySelectItems: TOption[] = useMemo(() => {
        return deliveryVariantList.map((item) => ({label: item.name, value: String(item.id)}));
    }, [deliveryVariantList]);
    return (
        <>
            <p className="optima__form-comment">Введите адрес, а мы покажем вам варианты доставки</p>
            <OrderAddressControl
                address={address}
                coordinates={coordinates}
                handleAddressChange={handleAddressChange}
                setIsAddressBlurred={setIsAddressBlurred}
                setValue={setAddressValue}
                value={addressValue}
            />
            {isAddressIncorrect(coordinates, address) && isAddressBlurred && (
                <div className="optima__address">
                    <p className="optima__address-message">Адрес неточный. Укажите улицу и дом или уточните адрес на карте.</p>
                    <div className="optima__address-button">
                        <Button onClick={openModal}>Открыть карту</Button>
                    </div>
                </div>
            )}
            <LoadingStatus status={orderReceivingStatus}>
                <OrderDeliveryTypeControl
                    deliverySelectItems={deliverySelectItems}
                    deliveryVariantId={deliveryVariantId}
                    handleDeliveryVariantChange={handleDeliveryVariantChange}
                />
            </LoadingStatus>
            {deliveryVariantItem && (
                <div className="optima__form-item">
                    <Calendar
                        allowDateList={deliveryVariantDateList}
                        name="optima-date"
                        onChange={handleDateChange}
                        placeholder="Выберите дату доставки"
                    />
                </div>
            )}
            {date && (
                <OrderDeliveryTimeList
                    items={deliveryVariantTimeList}
                    name="optima-deliveryTimeIndex"
                    onChange={handleTimeItemChange}
                    value={timeItemIndex}
                />
            )}
        </>
    );
};
