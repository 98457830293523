// @flow
import type {TRequestBody, TRequestQueryParameters} from 'modules/common/services/http';
import * as queryString from 'query-string';

type TRequestParams = $ReadOnly<{|
    body?: TRequestBody,
    options?: $ReadOnly<RequestOptions>,
    query?: TRequestQueryParameters,
|}>;

export type TResponseDadata<T> = TResponseDadataSuccess<T> | TResponseDadataError;

type TResponseDadataSuccess<T> = {
    data: T,
    status: 'success',
};

type TResponseDadataError = {
    status: 'error',
};

/**
 * Сервис для работы с dadata
 */
export class DadataService {
    /** Базовый путь, на который отправляются запросы */
    _baseUrl: string;

    /** Токен для авторизации в сервисе */
    _token: string;

    /**
     * @param host Адрес хоста, на который отправляются запросы
     * @param path относительный путь до базовой точки АПИ
     * @param token токен для авторизации в сервисе
     */
    constructor(host: string, path: string, token: string) {
        this._baseUrl = `${host}/${path}`;
        this._token = token;
    }

    /**
     * Отправляет запрос
     * @param method Метод запроса
     * @param pathname Часть пути относительно хоста
     * @param requestParams Параметры запроса
     * @returns Результат запроса
     */
    async request<T>(method: string, pathname: string, {body, options, query}: TRequestParams = {}): Promise<TResponseDadata<T>> {
        const queryStr = query ? queryString.stringify(query, {arrayFormat: 'bracket'}) : null;
        const baseUrl = this._baseUrl + pathname;
        const path = queryStr ? `${baseUrl}?${queryStr}` : baseUrl;

        const requestOptions: RequestOptions = {method, mode: 'cors', ...options};
        const headers = new Headers(requestOptions.headers);
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        if (this._token) {
            headers.set('Authorization', `Token ${this._token}`);
        }

        requestOptions.headers = headers;

        if (body) {
            requestOptions.body = JSON.stringify(body);
        }

        try {
            const response = await fetch(path, requestOptions);

            if (response.ok) {
                const data: T = await response.json();
                return {
                    data,
                    status: 'success',
                };
            }

            return {status: 'error'};
        } catch (error) {
            return {status: 'error'};
        }
    }
}
