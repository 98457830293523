// @flow
import {HandleChooseTabContext} from 'app/context';
import cn from 'classnames';
import {TYPES_COUNT} from 'modules/order/constants';
import React, {useContext, useCallback} from 'react';

type TProps = {
    checked: boolean,
    id: string,
    index: number,
    label: React$Node,
    name: string,
    onChange: (SyntheticInputEvent<HTMLInputElement>) => void,
    value: string,
};

/**
 * Компонент итема типа заказа
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderTypeItem = ({checked, name, onChange, label, value, id, index}: TProps) => {
    const handleChooseContext = useContext(HandleChooseTabContext);

    const handleChoose = useCallback(
        (e) => {
            onChange(e);

            if (!checked && handleChooseContext) {
                const {callback, list} = handleChooseContext;

                if (callback && list && list.length >= TYPES_COUNT) {
                    callback(list[index]);
                }
            }
        },
        [index, handleChooseContext, checked, onChange]
    );

    return (
        <div className="optima__type-item">
            <input checked={checked} className="optima__type-item-input" id={id} name={name} onChange={handleChoose} type="radio" value={value} />
            <label className="optima__type-item-content" htmlFor={id}>
                <span className={cn('optima__type-item-label', 'text-ellipsis')}>{label}</span>
            </label>
        </div>
    );
};
