// @flow
import cn from 'classnames';
import React from 'react';

type TProps = {
    id: string,
    name?: string,
    value: string,
};

/**
 * Компонент выбора итема самовывоза
 * @param props Свойства
 * @returns React-компонент
 */
export const OrderPickupChooser = ({id, name, value}: TProps) => {
    return (
        <div className="optima__pickup-chooser">
            <input className="optima__pickup-chooser-input" id={id} name={name} type="radio" value={value} />
            <label className="optima__pickup-chooser-content" htmlFor={id}>
                <span className={cn('optima__pickup-chooser-label', 'text-ellipsis')}>Выбрать</span>
            </label>
        </div>
    );
};
