// @flow
import {ADDRESS_ACTION_TYPE} from 'modules/order/constants';
import type {
    TActionGetAddrGeoClear,
    TActionGetAddrGeoListFail,
    TActionGetAddrGeoListStart,
    TActionGetAddrGeoListSuccess,
    TActionGetAddrSugClear,
    TActionGetAddrSugListFail,
    TActionGetAddrSugListStart,
    TActionGetAddrSugListSuccess,
} from 'modules/order/reducers/address';
import type {TAddressGeolocationQuery, TAddressSuggestQuery} from 'modules/order/types/address';
import type {TThunkAction} from 'types';

/**
 * @returns экшен
 */
function getAddressSuggestListFail(): TActionGetAddrSugListFail {
    return {
        type: ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_FAIL,
    };
}

/**
 * @returns экшен
 */
function getAddressSuggestListStart(): TActionGetAddrSugListStart {
    return {
        type: ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_START,
    };
}

/**
 * @param payload данные
 * @returns экшен
 */
function getAddressSuggestListSuccess(payload): TActionGetAddrSugListSuccess {
    return {
        payload,
        type: ADDRESS_ACTION_TYPE.GET_SUGGESTION_LIST_SUCCESS,
    };
}

/**
 * @returns экшен
 */
export function clearAddressSuggestList(): TActionGetAddrSugClear {
    return {
        type: ADDRESS_ACTION_TYPE.CLEAR_SUGGESTION_LIST,
    };
}

/**
 * @param query параметры запроса
 * @returns thunk-экшен
 */
export function getAddressSuggestList(query: TAddressSuggestQuery): TThunkAction<Promise<void>> {
    return async (dispatch, getState, {addressApi}) => {
        dispatch(getAddressSuggestListStart());
        try {
            const addressListResponse = await addressApi.getSuggestList(query);
            if ('error' !== addressListResponse.status) {
                dispatch(getAddressSuggestListSuccess(addressListResponse.data.suggestions));
            } else {
                dispatch(getAddressSuggestListFail());
            }
        } catch (e) {
            dispatch(getAddressSuggestListFail());
        }
    };
}

/**
 * @returns экшен
 */
function getAddressGeolocListFail(): TActionGetAddrGeoListFail {
    return {
        type: ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_FAIL,
    };
}

/**
 * @returns экшен
 */
function getAddressGeolocListStart(): TActionGetAddrGeoListStart {
    return {
        type: ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_START,
    };
}

/**
 * @param payload данные
 * @returns экшен
 */
function getAddressGeolocListSuccess(payload): TActionGetAddrGeoListSuccess {
    return {
        payload,
        type: ADDRESS_ACTION_TYPE.GET_GEOLOCATION_LIST_SUCCESS,
    };
}

/**
 * @returns экшен
 */
export function clearAddressGeolocList(): TActionGetAddrGeoClear {
    return {
        type: ADDRESS_ACTION_TYPE.CLEAR_GEOLOCATION_LIST,
    };
}

/**
 * @param query параметры запроса
 * @returns thunk-экшен
 */
export function getAddressGeolocList(query: TAddressGeolocationQuery): TThunkAction<Promise<void>> {
    return async (dispatch, getState, {addressApi}) => {
        dispatch(getAddressGeolocListStart());
        try {
            const addressListResponse = await addressApi.getGeolocateAddress(query);
            if ('error' !== addressListResponse.status) {
                dispatch(getAddressGeolocListSuccess(addressListResponse.data.suggestions));
            } else {
                dispatch(getAddressGeolocListFail());
            }
        } catch (e) {
            dispatch(getAddressGeolocListFail());
        }
    };
}
