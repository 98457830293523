// @flow
import {OrderForm} from 'modules/order/components/OrderForm';
import React from 'react';

/**
 * Корневой компонент
 * @returns React-компонент
 */
export const RootContainer = () => {
    return <OrderForm />;
};
