// @flow
import React from 'react';

type TProps = {
    content: string,
};

/**
 * Компонент итема для инпута с автозаполнением
 * @param props Свойства
 * @returns React-компонент
 */
export const SuggestItem = ({content}: TProps) => {
    return <p className="optima__suggest-item">{content}</p>;
};
